import React from 'react'
import PageTitle from '../components/PageTitle'
import GiftCardForm from '../components/GiftCardForm'
import { Elements } from 'react-stripe-elements'
import Layout from '../components/layout'
import OpenTable from '../components/OpenTable'
import styled from '@emotion/styled'
import {
  plantinBoldItalic,
  buttonStyles,
  plantin,
  buttonHoverStyles,
} from '../styles'
import CopyBox from '../components/CopyBox'


const OTWrapper = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  () => ({})
)


const ReservationsPage = ({ data }) => (
  <Layout>
    <PageTitle header="Reservations" />
    <OTWrapper>
        <CopyBox copy={<span>For Parties of six or more, please call us at <a href='tel:5403389800'>(540) 338–9800</a> to make a reservation.</span>}>
        </CopyBox>
      <OpenTable />
    </OTWrapper>
  </Layout>
)

export default ReservationsPage
