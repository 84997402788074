import styled from '@emotion/styled'
import React from 'react'
import { gentonaBook, plantin, plantinBoldItalic } from '../../styles'

const CopyBoxWrapper = styled.section`
  padding: 1rem;
  @media screen and (min-width: 920px) {
    max-width: 55%;
    margin: 0 auto 3rem auto;
  }
`

const Copy = styled.p`
  margin-bottom: 3rem;
  font-family: ${plantin};
  font-size: 2rem;
  line-height: 1.35;
  & > b {
    font-family: ${plantinBoldItalic};
  }
  a {
    text-decoration: underline;
    font-family: ${gentonaBook};
  }
  &:first-child:after {
    content: '';
    display: table;
    clear: both;
  }
  .intro-copy {
    font-family: ${plantinBoldItalic};
    font-size: 2.8rem;
  }
`

const CopyBox = ({ copy, children }) => (
  <CopyBoxWrapper>
    <Copy>{copy}</Copy>
    {children}
  </CopyBoxWrapper>
)

export default CopyBox
